@import '/src/assets/styling/variables.scss';

.main{
    background-color: $sigma-base-color;

    background-color: $secondary-color;
}

.mainContainer{
    background-color: white; 
}