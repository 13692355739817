@import "src/assets/styling/variables";

.container {
    background-color: #383940;
    display: flex;
    justify-content: center;
    position: relative;
}
.mobileSideBarHamburger{
    position: absolute;
    left: 500px;
    z-index: 10;
    height: 60%;
    top:20%;
    left: 1%;
    color: lightblue;
    display: none;
}

@media only screen and (max-width: 600px){
    .mobileSideBarHamburger{
        display: block;
    }
}

.inputSearchBar {
    // margin: 1vw 0;
    background-color: #56575c;
    color: white;
    // width: 40vw;
    // padding: 0.5vw;
    border: 1px solid rgb(120, 118, 118);
    border-radius: 10px;
    outline: none;
}

.searchIcon {
    font-size: 1.1rem;
    position: absolute;
    color: white;
    opacity: 70%;
    left: 20px;
    top: 14px
}

.searchButton{ 
    position: absolute;
    right: 20px;
    font-size: 0.8rem !important;
    padding: 2px 10px !important;
    top:13px;
}

.relativeDiv{
    position: relative
}

.inputPadding{
    padding:  5px 5px 5px 38px;
}