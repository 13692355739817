@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import "src/assets/styling/variables";

h1 {
    margin: 0;
}

body {
    font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Oswald', sans-serif;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: $primary-color  !important;
    border-color: $third-color  !important;
}

:root {
    --toastify-color-success: #17a099;
    // --toastify-color-success: rgb(214, 47, 47);
}