@import "src/assets/styling/variables";

.lettersMenuDiv{
    display: flex; 
    flex-direction: row;
    justify-content: center;
    margin: 10px 0;
    // width: 50vw;
    // margin: 2vw auto;
    flex-wrap: wrap;
}

.lettersMenu{
    margin: 0 0.5vw;
    color: $primary-color;
    font-weight: bold;
    opacity: 50%;
    font-size: 1.5rem;
    cursor: pointer;
}

.lettersMenu:hover{
    opacity: 100%;
}

.container{
    // background-color: red;
    padding: 5vw 10vw;
}

.lettersDiv{
    // background-color: blue;
    display: flex;
}

.letter{
    // background-color: blue;
    width: 20%;
}

.authorsPerLetter{
    // background-color: yellow;
    width: 80%;
}