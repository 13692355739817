@import "src/assets/styling/variables";
.commentIcon{
    font-size: 25px;
    margin-right: 5px;
}


.commentUsername{
    margin:0;
    font-style: italic;
    margin-right:5px;
}
.commentTimeCreated{
    margin: 0;
    font-style: 'italic';
    font-weight: 600
}

.file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: white; 
    padding: 5px;
    border: 2px solid $primary-color;
    border-radius: 10px;
    cursor: pointer;
}
.file:hover{
    background-color: rgb(208, 223, 237);
 
}


.repliedText{
    background-color: $primary-color;
  
}
.repliedText:hover{
    background-color: #115651 !important;
}
 

.repliedText *{
    color:white; 
}