

.editButton{
    cursor: pointer;
    color: lightgray;
    // position: absolute;
    right: -20px;
    top: 45%;
    margin-left: 10px;
    // display: none;
}

.editButton:hover{
    color: gray;
}