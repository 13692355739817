@import "src/assets/styling/variables";

.container{
}

.firstPart{
    border-bottom-left-radius: 20px;
    // padding: 5vw 20vw;
    background-color: $third-color;
    // background-color: $primary-color;
    color: white
}

// .secondPart{
//     padding: 5vw 20vw;   
// }

// .secondPart h4{
//     text-align: center;
//     color: $primary-color;
//     text-transform: uppercase;
//     font-weight: bold;
//     margin-bottom: 2vw;
// }