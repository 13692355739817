@import "/src/assets/styling/variables.scss";

.footer{
    z-index: 15;
    background-color: $sigma-base-color;
    background-color: hsl(232, 7%, 24%);
    color: white;

    // position: absolute;
    bottom: 0;
    width: 100%;
}

.container{
    margin: 0 auto;
    width: 80vw;
    padding: 3vw 1vw;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerDivs{
    width:180px; 
}

.editSupportDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // background-color: red;
}

.footerLinks{
    color: white;
    text-decoration: none; 
}



.logoImage{
    height: 50px;
}
.copyrightsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
 

.footerDiv{
    // position: relative;
    min-height: 19vh;
}