@import "src/assets/styling/variables";

.container {
    // padding: 5vw 20vw; 
}

@media only screen and (max-width:600px) {

    .edittingButtons {
        display: none;
    }
}

.chapter {
    // background-color: blue;
    max-width: 50vw;
    margin: 0 auto;
    padding: 5vw 2vw;
}
.subArticleDescription{
    font-weight: bold;
    position: sticky;
    top: 10.3rem;
    background-color: white;
    border-bottom: 1px solid lightgray;
}

.chapterTitle {

    color: $primary-color;
}

.chapter h2,
h5 {
    margin: 0;
}

.chapterAuthors {
    margin: 3vw 0;
}

.chapterSections {}

.previousNext {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.previousNext div {
    padding: 1.5vw;
}

.previousNext div i {
    margin: 0 0.7vw;
}

.sectionsList {
    width: 90%;
    margin-left: auto;
}
// style={{position: 'sticky', top: '9rem',padding:'5px 8px', fontSize:'15px', backgroundColor: 'dodgerblue'}}

.chapterButtons{
    position: absolute;
    right: 0;
}

@media screen and (max-width:750px) {
    .chapterButtons{
        position: unset;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // background-color: dodgerblue;
    }

    .chapterButtons a{
        margin-bottom: 5px;
    }

    .buttonsContentPage{
        margin: 10px;
    }
}

.stickyElement{
    position: sticky;
    top: 0;
    border-bottom: 1px solid lightgray;
    background-color:white ;
    text-align: center;
    font-size: 1.5rem;
    z-index: 12;
    padding: 5px 0;
}

table, td{
    border: 1px solid black !important;
    border-collapse: collapse;
}

td{
    padding: 1rem 1rem;
}

.stickyZero{
    position: sticky;
    top:0;
    background-color: white;
    z-index: 1000;
    padding-top: 0.5rem;
}