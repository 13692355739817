@import "src/assets/styling/variables";

.file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: white; 
    padding: 5px;
    border: 2px solid $primary-color;
    border-radius: 10px;
    cursor: pointer;
}

.repliedText{
    background-color: $primary-color;
  
}
.links {
    // width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // align-items: center; 
    // padding: 0 20px;
    // background-color: $third-color; 
}

.link {
    //     list-style-type: none;
    text-decoration: none;
    color: rgb(228, 255, 234);
    margin: 0 10px;
    //     padding: 0 15px;
    text-transform: uppercase;
    font-weight: bold;

    // font-size: 11px;
}

.repliedText:hover{
    background-color: #115651 !important;
}
 

.repliedText *{
    color:white; 
}

.fileInfo{
    display: flex; 
    align-items: center;
    width: 100%;
}


.reply{
    margin-left: auto;
    margin-right: 1%;
}

.fileButton{
    margin-left: 10px;
}

.file:hover{
    background-color: rgb(208, 223, 237);
 
}

.fileButton:hover{
    background-color: $primary-color;
    color: white;
}

.commentIcon{
    font-size: 25px;
    margin-right: 5px;
}

.commentText{ 
    font-weight: 700;
    font-size: 1.1rem;
    margin:0
}

.commentUsername{
    margin:0;
    font-style: italic;
    margin-right:5px;
}

.commentTimeCreated{
    margin: 0;
    font-style: 'italic';
    font-weight: 600
}

.commentEmail{
    margin:0
}

.modalBodyText{
    text-transform: unset;
    margin-bottom: 30px
}

.modalTitleText{
    text-transform: capitalize;
}

.italicFont{
    font-style: italic;
}

.whiteBackgroundColor{
    background-color: #ece8e2;
}

.writeReply{
    margin-top: 30px;
    font-weight: 600; 
}