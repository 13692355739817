@import "src/assets/styling/variables";


// Old Version
//.chapterContainer {
//     padding: 10px 20px;
//     display: flex;
//     flex-direction: column;

//     width: 15vw; 

// }

// .chapterContent {
//     display: flex;
// }

.chapterText {
    text-align: center;
}

// .showChapterText{
    
//     visibility: visible !important;
// }

// .chapterText:hover {
//     width: auto;
//     // opacity: 1;
// }

// .articlesContainer{ 
//     width: 10vw; 
// }

// .articlesContainer p{
//     margin: 5px 10px; 
// }


// .chapterOutline{
//     border: 5px solid #d4d4d4;
//     border-right-style: none; 
// }

// .articleView{ 
//     padding: 10px;  
// }


//New Version
.container {
    // overflow-y: scroll;
    // background-color: $primary-color;
    background-color: #10534f;
    // width: 20vw;
    // height: 100%;
    padding: 1vw;
    // width: 8%;
    transition: all 0.3s ease-in-out;
    // height: 100%;
    // position: fixed;
    // top:5.5rem;
    top: 0;
    position: sticky;
    // height: 2400px;
    
    // z-index: 10;
    z-index: 120;
}

.container *{
    font-size: 15px;

}

.overflow{
    overflow: auto;
    height: 100vh;
}

.container:hover{
    // width: 14%;
}

.division h4{
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.division{
    cursor: pointer;
    // margin-left: auto;
    margin-right: 0;
    // background-color: red;
    // width: 20%;
    // margin-bottom: 0.5vw;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 0.5vw;
    color: $secondary-color;
    // border-radius: 50%;
    text-decoration: none;
}

.chapter {
    cursor: pointer;
    // margin-left: auto;
    margin-right: 0;
    // background-color: red;
    // width: 20%;
    // margin-bottom: 0.5vw;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    padding: 0.5vw;
    color: $secondary-color;
    // border-radius: 50%;
    text-decoration: none;
}
.chapter>h4{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chapter:hover{
    color: gray;
}

.chapter:hover{
    background-color: #17a099;
    border-radius: 8px;
    color:white
}




.chapter h4 {
    margin: 0;
    font-size: 1.2rem;
}

.paragraphWrapper{
    display: flex;
    // position: relative;
}

.paragraphWrapper:hover + .editButton{
    display: block;
}

.paragraphNumber{
    margin-right: 30px;
    padding-left: 5px;
    // marginRight: '30px', paddingLeft: '5px
}
.articleTitle{
    position: sticky;
    top: 0rem;
    padding: 15px 10px;
    border-bottom: 1px solid lightgray;
    background-color:white ;
    z-index: 10;  
}
.partText{
    text-align: center;
}
.partTitle{
    border-bottom: 1px solid lightgray;
    background-color:white ;
    text-align: center;
    font-size: 1.5rem;
    z-index: 12;
}

.divisionTitle{
    border-bottom: 1px solid lightgray;
    background-color:white ;
    position: sticky;
    top:7.3rem;
    text-align: center;
    font-size: 1.4rem;
    z-index: 11;
}

.divisionTitle{
    border-bottom: 1px solid lightgray;
    background-color:white ;
    text-align: center;
    font-size: 1.4rem;
    z-index: 11;
}

.chapterTitle{
    border-bottom: 1px solid lightgray;
    background-color:white ;
    text-align: center;
    font-size: 1.5rem;
    z-index: 11;
}

.redText{
    color:red;
    border: 1px solid black;
    padding: 10px
}
.subArticleTitle4{
    padding: 5px 8px;
    font-size: 17px;
    background-color:white ;
    z-index: 5;
    font-weight: normal;
}

.subArticleTitle3{
    padding: 5px 8px;
    font-size: 17px;
    background-color:white ;
    z-index: 5;
    font-weight: normal;
}

.subArticleTitle2{
    padding: 5px 8px;
    font-size: 17px;
    background-color:white ;
    z-index: 5;
    color: darkblue;
    font-weight: normal;
}


.subArticleTitle1{
    padding: 5px 8px;
    font-size: 19px;
    background-color:white ;
    z-index: 5;
    color: darkblue;
    font-weight: normal;
}

.subArticleTitle{
    
    padding: 5px 8px;
    font-size: 20px;
    // border-bottom: 1px solid lightgray;
    background-color:white ;
    z-index: 5;
    
    font-weight: normal;
    // background-color: green;
}

@media only screen and (max-width: 600px){
    .container{
        z-index: 10000;
        // width: 0;
        // overflow: hidden;
        padding: 0 2.5rem;
        margin: 0;
    }

    // .containerShow{
    //     width: 50% !important;
    //     padding: 1vw;
    // }
    .closeContent{
        display: block;
    }

}

.closeContent{
    display: none;
}

.closeButtonWrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.titleContent{
    margin: 1rem;
    color: white;
}

.lawSection{
    position: relative;
}

// .showing{
//     transition: all 0.2s ease-in-out;
//     z-index: 100000000;
//     transform: translateX(0);
// }
// .notShowing{
//     transition: all 0.2s ease-in-out;
//     z-index: -1;
//     transform: translateX(-1000px);
// }

@media only screen and (max-width: 768px){
    .articleTitle{
        top: 3.4rem;
        padding: 5px 10px;
    }
    

    .partTitle{
        top: 0rem;

    }

    .chapterTitle{
        top: 1.7rem;
    }
    
}
.subArticleTitleLetter{
    margin-left: 40px;
    font-weight: normal;
}
 

.paragraphBackground{
    background-color: $primary-color !important;
}

.paragraph{
    text-decoration: none; 
    font-weight: 500;
    color: $secondary-color;
}

.paragraphBackground:hover{
    background-color: $third-color !important
}

.paragraph:hover{
    color: whitesmoke; 
}

.commentIcon{
    opacity: 40%;
    cursor: pointer;
    position: absolute;
    left: 101%;
    top: 0;
}

.commentIcon:hover{
    opacity: 70%;
}
.divisionTextContentPage{
    text-align: center;
}

.chapterTextContentPage{
    text-align: center;
    font-style: italic;
    border-bottom: 1px solid lightgray;
    background-color:white ;
    z-index: 11;
}
.partTextContentPage{
    text-align: center;
    z-index: 15;
    background-color: white;
}

.partTextContentPage h2, .chapterTextContentPage h2{
    font-size: 1.5rem;
}

.articleNumber{
    margin-left: 40px;
}

.articleTitle{
    font-weight: normal;
}

.chapterSideBar{
    margin-left: 0.7rem;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw; 
    border-radius: 8px;
    background-color: #208b84;
}

.chapterSideBar h4{
    font-size: 1rem;
}

.chapterSideBar:hover{
    background-color: #50afa9;
    // border-radius: unset;
}

.divisionSideBar{
    margin-left: 1rem;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw; 
    border-radius: 8px;
    background-color: #25a49c;
}


.articleSideBar{
    margin-left: 1.5rem;
    // width: 90%;
    // max-width: 90%;
    background-color: #58b9b2;
 
    border-radius: 8px;     
    margin-bottom: 0.5vw; 
}

.articleSideBar h4{
    font-size: 1rem;  
}

.articleSideBar:hover{
    background-color: red; 
    background-color: #50afa9;
}

.gotoLabel{
    // margin: 0.5vw 0 !important;
    margin-bottom: 15px;
}

.chapterListSidebar{
    margin-bottom: 10px;
}

.whitesmokeBackground{
    background-color: whitesmoke;
}