.contentWrapper{
    margin-left: 2rem;
    margin-bottom: 0;
}
.contentWrapper *{
    color:black;
    text-decoration: none;
}

.contentWrapper>a{
    cursor: unset;
    text-decoration: none;
}

.contentWrapper>a:hover{
    color: black;
}

.ArticleLink:hover {
    cursor: pointer !important;
    color:blue !important
}

.ChapterLink{
    font-size: 20px;
}

.PartLink{
    font-size: 22px;
}

.DivisionLink{
    font-size:  18px;
}

.ArticleLink{
    font-size: 16px;
}


.smallSpacing{
    margin: 0;
}


