@import "src/assets/styling/variables";

.paragraph{
    margin: 0;
//     margin-bottom: 30px;
// margin-top: 50px
}

.comment{
    // background-color: teal;
    background-color: rgb(231, 231, 231);
    margin-bottom: 10px;
    border-radius: 20px;
    border: 2px solid $primary-color;
}

.reply{
    background-color: $primary-color;
    color: whitesmoke;
    border-radius: 20px;
    margin-bottom: 20px;
}

.italicFont{
    font-style: italic;
}