.backgroundEl{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: red;
    top: 0;
    left: 0%;
    right: 0;
    bottom: 0;
}

.writeCommentQuestionText{
    text-transform: unset;
}

.showPersonalInfo{
    margin-right: 5px;
}

.ChapterLink, .PartLink, .ArticleLink{
    
    border: none;
    background-color: unset;
}

.ChapterLink, .ChapterLink * {
    font-size: 18px;
    cursor: default !important; 
    margin-left: 10px; 
} 
.PartLink, .PartLink *{
    font-size: 19px;
    cursor: default !important; 
} 
.ArticleLink{
    font-size: 17px;
    cursor: pointer; 
    margin-left: 20px;
}
.ArticleLink:hover, .ArticleLink *:hover{
    color: blue;
    cursor: pointer !important;
}

.contentWrapper{
    border: 1px solid gray;
    height: 15rem;
    overflow-y: auto;
}

.contentWrapper *{
    padding: 0;
}

.buttonIgnore{
    border: none;
    background-color: unset;
}