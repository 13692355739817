@import "src/assets/styling/variables";


// First version
// .header{
//     background-color: $sigma-base-color;
// }

// .headerImage{
//     height: 100px;
//     width: 100px;
//     margin: 0 20px;
// }


// .container{
//     margin: 0 auto;
//     width: 80vw;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// }

// .logoBrand{
//     display: flex;
//     align-items: center;
// }


// .links{ 
//     display: flex;
//     flex-direction: row;
//     align-items: center; 

// }

// .link{ 
//     list-style-type: none;
//     text-decoration: none;
//     color: black; 
//     padding: 0 15px;
//     text-transform: uppercase;
//     font-weight: 600;
//     font-size: 14px;

//     -webkit-transition: color 1s;
//     -moz-transition:    color 1s;
//     -ms-transition:     color 1s;
//     -o-transition:      color 1s;
//     transition:         color 1s;
// }

// .link:hover{
//     color: white
// }

//Second  version

.header {
    padding: 0;
    background-color: $sigma-base-color;
    background-color: $primary-color;
    position: sticky;
    top: 0px;
}

// .header {
//     position: sticky;
//     top: 0px;
//     background-color: red;
//     background-color: $primary-color;
//     height: 6rem;
//     transition-property: all; 
//     transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//     transition-duration: 500ms;
//   }
  
//   .header.down {
//     top: -6rem;
//   }

.logoImage{
    text-align: center
}

.container {
    background-color: $primary-color;
    // margin: 0 auto;
    // width: 80vw;

    // display: flex;
    // color: white;  
}

.titleDiv {
    // background-color: red;
    width: 25%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 15px 0;
}

h3,
h5 {
    text-transform: uppercase;
}

.titleDiv h3 {
    margin: 0;
    font-size: 16px;
}

.titleDiv h5 {
    margin: 0;
    font-size: 13px;
}


.links {
    // width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    // align-items: center; 
    // padding: 0 20px;
    // background-color: $third-color; 
}

.link {
    //     list-style-type: none;
    text-decoration: none;
    color: rgb(228, 255, 234);
    margin: 0 10px;
    //     padding: 0 15px;
    text-transform: uppercase;
    font-weight: bold;

    // font-size: 11px;
}

.link:hover {
    color: white
}

.mainTitleLink{
    text-decoration: none;
    color: white;
}

.mainTitleLink:hover{
    color:white;
}

.legalCommentaryText{
    font-size: 16px;
    font-weight: bold;
}

.executiveIssueText{
    font-size: 13px;
}

@media screen and (max-width: 750px) {
    .links {
        // justify-content: unset;
        background-color: $third-color;
        overflow-y: hidden; 
        overflow-x: auto;  
    }

    .link { 
        text-transform: unset;
        font-weight: unset;
    }

    .mainLinks {
        display: flex;
        align-items: center;
        // justify-content: space-evenly;
    }

    .header{
        position: unset !important;
    }
}