@import "src/assets/styling/variables";

.file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: white; 
    padding: 5px;
    border: 2px solid $primary-color;
    border-radius: 10px;
}

.fileInfo{
    display: flex; 
    align-items: center;
}

// .fileButton{
//     border: 2px solid $primary-color;
//     color: $primary-color;
//     background-color: white;
//     border-radius: 10px; 
//     font-weight: 600;
//     margin-left: 10px;

// }
.fileButton{
    margin-left: 10px;
}

.file:hover{
    background-color: rgb(208, 223, 237);
}

.fileButton:hover{
    background-color: $primary-color;
    color: white
}

.searchResults{
    font-weight: 700;
    font-size: 1.1rem;
     margin: 0 ;
}