@import "src/assets/styling/variables";

.section{
    max-width: 50vw;
    margin: 0 auto;
    padding: 5vw 2vw;
    // color: $primary-color;  
}

.sectionTitle h2{
    font-weight: 600;
    margin-bottom: 2vw;
    color: $primary-color;
}

.sectionParagraph{
    font-weight: 500;
}

.sectionList{
    display: flex;
    flex-direction: column;
}

.sectionListItem{
    display: flex;
}

.sectionListItemNumber{
    width: 5%; 
}

.sectionListItemText{
    width: 95%
}